import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ChannelsTable from "../components/ChannelsTable";

export function FTM400() {

    const openPort = async () => {
        // const port = await (navigator as any).serial.requestPort();
        const ports = await (navigator as any).serial.getPorts();

        console.log(ports);

        const port: SerialPort = await (navigator as any).serial.requestPort({ });

        console.log(port);
    }

        const addFiles = async () => {

        try {
        const directory = await (window as any).showDirectoryPicker({
            startIn: 'desktop'
        })
        alert(JSON.stringify(directory));
        } catch(openError) {
        alert(JSON.stringify(openError));
        }
    }

    return (
    <Container maxWidth="xl">

<Typography variant="h3" component="h2" gutterBottom>
          FTM-400
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {'Handy web-based tool for configuring your Rig.'}
          {/* {'The footer will move as the main element of the page grows.'} */}
        </Typography>
        {/* <Typography variant="body1">Sticky footer placeholder.</Typography> */}
        <ChannelsTable />
        <Button variant="contained" onClick={() => openPort()}>Connect</Button>
        <Button variant="contained" onClick={() => addFiles()}>Add files</Button>


    </Container>);
}